// MAIN.JS
jQuery(function($) {

	/**********************************************/

	$(window).load(function() {
		$(this).resize(function() {

			setTimeout(function() {
				var height = $(".module-menu-bar").innerHeight();
				if ($(".module-menu-bar").css("display") == "none") height = 0;
				$("body").css("padding-top", height + "px");
			}, 50);

		}).resize();
	});

	/**********************************************/

	$(".module-slideshow").layerSlider({
		autoStart: true,
		startInViewport: false,
		pauseOnHover: false,
		showCircleTimer: false,
		sublayerContainer: 1500,
	});

	$(".module-slideshow-mobile").layerSlider({
		autoStart: true,
		startInViewport: false,
		pauseOnHover: false,
		showCircleTimer: false,
	});

	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {

			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image'
		});
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});

	}

	/**********************************************/

	// MENU
	var menu = ".menu, .module-menu-bar-mobile-overlay";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel();
	}

	/**********************************************/

	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});
	}

});
